import React,{ useRef, useState, useEffect, useContext } from 'react'
import { Box, Heading, Text } from 'theme-ui'
import Img from "gatsby-image"
import Form from '../components/form'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import LangContext from "../components/Context/LangContext"
import { handleLinkLanguage } from "../components/helpers"
import { graphql } from 'gatsby'

const Contactpage = ({ data }) => {
    const {
        setPage, currentPage, setLang, currentLang
      } = useContext(LangContext)

    useEffect(() => {
        setPage("contact")
    }, [])


    // Animations
    useEffect(() => {
        gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText);
        gsap.utils.toArray(".splittext").forEach((text, index) => {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: text,
                    start: 'top bottom',
                    end: 'bottom top',
                    toggleActions: "play none none none"
                }
            });
            new SplitText(text, {
                type: "lines",
                linesClass: `lineChild${index}`
            });
            new SplitText(text, {
                type: "lines",
                linesClass: "o-hidden"
            });
      
            tl.from(`.lineChild${index}`, { opacity: 0, y: 50, ease: "expo", stagger: 0.05,});
        })
    },[])
    const {
        titleRs,
        image,
        descriptionRs,
        formTitleRs,
    } = data.allDatoCmsContact.nodes[0]

    const datafake = [
        {
            fullname: 'Denis Kosovac',
            position: 'CEO',
            phone: '+77713051881',
            phone2: '+79265677636',
            email: 'denis.kosovac@primeberry.rs'
        },
        {
            fullname: 'Vanja Kosovac',
            position: 'Sales',
            phone: '+38169613138',
            phone2: '',
            email: 'vanja.kosovac@primeberry.rs'
        }
    ]

    const handleContacts = () => datafake.map(x => 
    <Box
    sx={{
        width: ['100%', '50%',],
        display: 'flex',
        flexDirection: ['column', 'column'],
        color: 'white',
        mb: 4,
        fontSize: 4,
    }}>
        <Text as="h2" sx={{
            fontFamily: 'heading',
            mb: 1,
        }}>{x.fullname}</Text>
        <Text sx={{
            mb: 1,
        }}>{x.position}</Text>
        <Text sx={{
            mb: 1,
            'a': {
                color: 'primaryPink',
                textDecoration: 'none',
            }
        }}><a href={`tel:${x.phone}`}>{x.phone}</a></Text>

        {x.phone2 !== "" && <Text sx={{
            mb: 1,
            'a': {
                color: 'primaryPink',
                textDecoration: 'none',
            }
        }}><a href={`tel:${x.phone2}`}>{x.phone2}</a></Text>}
        <Text sx={{
            'a': {
                color: 'primaryPink',
                textDecoration: 'none',
            }
        }}><a href={`mailto:${x.email}`}>{x.email}</a></Text>
        
    </Box>)

    return (
        <Box>
            <Box
                sx={{
                    width: "100%",
                    display: 'flex',
                    position: 'relative',
                    height: ['50vh','70vh'],

                }}>


                {/* Hero Slika */}

                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top:0,
                        left: 0,

                    }}>
                        <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            postion:'relative',
                            overflow:'hidden'

                        }}>
                    {/* <Img fluid={image.fluid} objectFit='cover' style={{height:'100%'}} imgStyle={{height:'100%', }}/> */}
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 1,
                        justifyContent: ['center','center','flex-end'],
                        alignItems: ['center','center','inherit'],
                        pl: [3,0,5],
                        pr: [3,0,5],
                        pt:[6,0,5],
                        pb:[5,0,3], 

                    }}>
                    <Heading className='splittext'
                        sx={{
                            fontSize: [7,8,'130px'],
                            color: 'white',
                            zIndex: 2,
                        }}>
                        {titleRs}
                    </Heading>
                    <Text className='splittext'
                        sx={{
                            pb: [0,0,3],
                            fontSize: [5,6,5],
                            ml: [0,0,2],
                            color: 'white',
                            textAlign: ["center", "left"],
                        }}>
                        {descriptionRs}
                    </Text>

                </Box>
                
            </Box>

            <Form 
            formTitle={formTitleRs}
            currentLang={currentLang}
            ></Form>

            <Box
            sx={{
                display: 'flex',
                flexDirection: ['column', 'row'],
                px: [3,5],

            }}>
                {handleContacts()}

            </Box>
        </Box>
    )
}

export default Contactpage

export const query = graphql`
query ContactRs {
  allDatoCmsContact {
    nodes {
      image {
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      titleRs
      descriptionRs

      formTitleRs
    }
  }
}`