import React, { useRef, useState } from 'react'
import { Box, Heading, Text, Input, jsx, Button } from 'theme-ui'
import { useForm, Controller } from "react-hook-form";
import NetlifyForm from "./netlifyForm";
/** @jsx jsx */

const BoxLove = ({ children }) => <Box
    sx={{
        position: 'relative',
        pb: [1, 1, 1, 4],
        mb: 3,
        width: ['100%','100%','50%',],
        '& input': {
            borderColor: 'white',
            py: 3,
            fontWeight: '400',
            outline: 'none',
            color: 'white',
            '&:focus': {
                borderColor: 'white',
            }
        }
    }}
>{children}</Box>

const WarningLove = ({ children }) => <Text
    fontSize={1}
    sx={{
        position: 'absolute',
        left: 3,
        bottom: [2, 2, 2, 0],
        color: 'red',
        letterSpacing: '0.1px',
        fontWeight: '100',
        fontSize: 2,
    }}>{children}</Text>

const BoxRow = ({ children }) => <Box
    sx={{
        display: 'flex',
        flexWrap: ['wrap','wrap', 'nowrap'],
        width: '100%',
        '& > div': {
            mr: 4,
        }
    }}>{children}</Box>


const Form = ({
    currentLang,
    formTitle,
}) => {
    const [processing, setProcessing] = useState(false)
    const [isCompleted, setCompleted] = useState(false)
    const [formValues, setFormValues] = useState({
    })
    const handleChange = e => setFormValues({ ...formValues, [e.target.name]: e.target.value })
    const { handleSubmit, register, getValues, errors, control, setValue } = useForm({
        mode: "onBlur"
    });

    const onSubmit = (values) => {
        setCompleted(true)
        setProcessing(true)
        return
    }
    
    const postSubmit = () => {
        console.log('formValues', formValues)
        setProcessing(false)
        return
    }

    const formRef = useRef(null);

    const handleLabelIme = () => {
        if (currentLang == "en") return "First name"
        if (currentLang == "ru") return "Имя"
        if (currentLang == "rs") return "Ime"
    }

    const handleLabelPrezime = () => {
        if (currentLang == "en") return "Family name"
        if (currentLang == "ru") return "Фамилия"
        if (currentLang == "rs") return "Prezime"
    }

    const handleLabelEmail = () => {
        if (currentLang == "en") return "E-mail"
        if (currentLang == "ru") return "Эл. почта"
        if (currentLang == "rs") return "elekt. adresa"
    }

    const handleLabelTelefon = () => {
        if (currentLang == "en") return "Phone"
        if (currentLang == "ru") return "Телефонный номер"
        if (currentLang == "rs") return "Telefon"
    }

    const handleLabelPoruka = () => {
        if (currentLang == "en") return "Message"
        if (currentLang == "ru") return "Сообщение"
        if (currentLang == "rs") return "Poruka"
    }

    const handleLabelSubmit = () => {
        if (currentLang == "en") return "Send"
        if (currentLang == "ru") return "Отправить сообщение"
        if (currentLang == "rs") return "Pošalji poruku"
    }

    const handleFormCompletedMessage = () => {
        if (currentLang == "en") return "Thank you! We'll get back to you as soon as possible!"
        if (currentLang == "ru") return "Спасибо, мы свяжемся с вами в ближайшее время."
        if (currentLang == "rs") return "Hvala na upitu! Kontaktiraćemo Vas čim budemo u mogućnosti."
    }

    const handleErrorLabel = (type) => {
        switch (type) {
            case 'minLength':
                if (currentLang == "en") return "Must be longer than 2 letters"
                if (currentLang == "ru") return "Должен быть длиннее 2 букв"
                if (currentLang == "rs") return "Mora biti duže od dva slova"
            break;
            case 'maxLength':
                if (currentLang == "en") return "Error"
                if (currentLang == "ru") return "ошибка"
                if (currentLang == "rs") return "greška"
            break;
            case 'required':
                if (currentLang == "en") return "Required"
                if (currentLang == "ru") return "необходимые"
                if (currentLang == "rs") return "Obavezno"
            break;
            case 'nospacing':
                if (currentLang == "en") return "No space between letters allowed"
                if (currentLang == "ru") return "Пробелы между буквами не допускаются"
                if (currentLang == "rs") return "Izbrišite razmake"
            break;
            case 'emailformat':
                if (currentLang == "en") return "Email is not correctly formatted"
                if (currentLang == "ru") return "Электронная почта неправильно отформатирована"
                if (currentLang == "rs") return "Nije dobro formatiran email"
            break;
        }
    }


    const onError = (errors, e) => console.log(errors, e)

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',

                flexDirection: 'column',
                px: [0,0,'50px'],
                py: [0,0,2],
            }}>
            <Box
                sx={{
                    width: ['100%', '100%', '100%'],
                    height: '100%',
                    display: 'flex',
                    ml: [0, 0, 0],
                    pb: [0, 0],



                }}>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        background: 'primaryBlue',
                        borderRadius: '15px',

                        pl: 3,
                        py: [0, 5],
                        pb: [5,5,],

                    }}>

                {isCompleted && 
                <Box
                sx={{
                    color: 'primaryPink',
                    fontSize: 5,
                    mb: 4,
                }}>
                   {handleFormCompletedMessage()} </Box>}

                {!isCompleted && <NetlifyForm
                    id="customerDetails"
                    preSubmit={handleSubmit(onSubmit, onError)}
                    postSubmit={postSubmit}
                    formValues={formValues}
                    method="post"
                    automaticHoneypot={true}
                    data-netlify="true"
                    name="contactForm"
                    formName="contactForm"
                    ref={formRef}
                >
                        <BoxRow>
                            <BoxLove>
                                <Input
                                    type="text"
                                    placeholder={handleLabelIme()}
                                    name="firstName"
                                    // bg={errors.firstName && '#ffb8b8'}
                                    sx={{
                                        borderColor: errors.firstName ? 'red!important' : 'inherit',
                                        borderWidth: errors.firstName ? '2px!important' : '1px',
                                    }}
                                    onChange={handleChange}
                                    color={errors.firstName && 'red'}
                                    mb={[errors.firstName ? 4 : 0, errors.firstName ? 4 : 0, errors.firstName ? 4 : 0, errors.firstName ? 0 : 0]}
                                    ref={register({
                                        minLength: { value: 2, message: handleErrorLabel('minLength')},
                                        maxLength: { value: 32, message: handleErrorLabel('maxLength')},
                                        required: handleErrorLabel('required'),
                                        pattern: {
                                            value: /^\S*$/,
                                            message: handleErrorLabel('nospacing'),
                                        }
                                    })}
                                />
                                <WarningLove>{errors.firstName ? <div>{errors.firstName.message}</div> : null}</WarningLove>
                            </BoxLove>

                            <BoxLove>
                                <Input
                                    type="text"
                                    name="lastName"
                                    placeholder={handleLabelPrezime()}
                                    sx={{
                                        borderColor: errors.lastName ? 'red!important' : 'inherit',
                                        borderWidth: errors.lastName ? '2px!important' : '1px',
                                    }}
                                    onChange={handleChange}
                                    color={errors.lastName && 'red'}
                                    mb={[errors.lastName ? 4 : 0, errors.lastName ? 4 : 0, errors.lastName ? 4 : 0, errors.lastName ? 0 : 0]}
                                    ref={register({
                                        minLength: { value: 2, message: handleErrorLabel('minLength')},
                                        maxLength: { value: 32, message: handleErrorLabel('maxLength') },
                                        required: handleErrorLabel('required'),
                                        pattern: {
                                            value: /^\S*$/,
                                            message: handleErrorLabel('nospacing')
                                        }
                                    })}
                                />
                                <WarningLove>{errors.lastName ? <div>{errors.lastName.message}</div> : null}</WarningLove>
                            </BoxLove>
                        </BoxRow>


                        <BoxRow>
                            <BoxLove>
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder={handleLabelEmail()}
                                    sx={{
                                        borderColor: errors.email ? 'red!important' : 'inherit',
                                        borderWidth: errors.email ? '2px!important' : '1px',
                                    }}
                                    color={errors.email && 'red'}
                                    mb={[errors.email ? 4 : 0, errors.email ? 4 : 0, errors.email ? 4 : 0, errors.email ? 0 : 0]}
                                    ref={register({
                                        required: handleErrorLabel('required'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: handleErrorLabel('emailformat')
                                        }
                                    })}
                                />
                                <WarningLove>{errors.email ? <div>{errors.email.message}</div> : null}</WarningLove>
                            </BoxLove>

                            <BoxLove>
                                <Input
                                    type="tel"
                                    name="phone"
                                    placeholder={handleLabelTelefon()}
                                    onChange={handleChange}
                                    sx={{
                                        borderColor: errors.phone ? 'red!important' : 'inherit',
                                        borderWidth: errors.phone ? '2px!important' : '1px',
                                    }}
                                    color={errors.phone && 'red'}
                                    mb={[errors.phone ? 4 : 0, errors.phone ? 4 : 0, errors.phone ? 4 : 0, errors.phone ? 0 : 0]}
                                    ref={register({
                                        required: handleErrorLabel('required'),
                                    })}
                                />
                                <WarningLove>{errors.phone ? <div>{errors.phone.message}</div> : null}</WarningLove>
                            </BoxLove>

                        </BoxRow>

                        <Box sx={{
                            mr: 4,
                            mb: 4,
                        }}>
                            <Input as="textarea"
                                name="message"
                                sx={{
                                    border: '1px solid white',
                                    outline: 'none',
                                    resize: 'none',
                                    color: 'white',
                                    fontWeight: 500
                                }}
                                onChange={handleChange}
                                placeholder={handleLabelPoruka()} ref={register} rows="7" name="notes" />
                        </Box>
                        <input type="hidden" name="bot-field" />
                            <input type="hidden" name="form-name" value="contact" />
                        <Button
                         onClick={() => setFormValues(getValues())}
                         >
                            {handleLabelSubmit()}
                    </Button>


                    </NetlifyForm>}
                </Box>
            </Box>

        </Box>
    )
}

export default Form